import * as React from "react";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import { Copyright } from "./components/Copyright";
import { useEthers } from "./connectors/useEthers";
import { SocialIcon } from "react-social-icons";
import Home from "./sections/Home";
import { Menu } from "./components/Menu";
import { TVFrame } from "./components/TvFrame";
import { Box } from "@mui/system";
import { Footer } from "./sections/Footer";
import About from "./sections/about";

export default function App() {
  const { account, error, deactivate } = useEthers();

  if (error) {
    console.log(error);
    console.log("deactivating");
    deactivate();
  }
  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      justifyItems="center"
      alignItems="center"
      sx={{ width: "100%" }}
    >
      <TVFrame>
        <Menu />
        <Home />
      </TVFrame>
      <About />
      <Footer />
    </Box>
  );
}
