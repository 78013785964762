import React from "react";
import { Container, Grid } from "@mui/material";
import Intro from "./Intro";
import JoinUs from "./JoinUs";
import Lore from "./Lore";
import Mechanics from "./Mechanics";
import Faq from "./Faq";

export const About = () => (
  <Container maxWidth="md">
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      mt={2}
    >
      <Grid item>
        <Intro />
      </Grid>
      <Grid item sx={{ width: "100%" }}>
        <Lore />
      </Grid>
      <Grid item>
        <Mechanics />
      </Grid>
      <Grid item>
        <Faq />
      </Grid>
      <Grid item>
        <JoinUs />
      </Grid>
    </Grid>
  </Container>
);
export default About;
