import React, { useState } from "react";
import { Typography } from "@mui/material";
import ReactPlayer from "react-player";
import { Waypoint } from "react-waypoint";

export const Lore = () => {
  const [shouldPlay, updatePlayState] = useState(false);

  const handleEnterViewport = function () {
    updatePlayState(true);
  };
  return (
    <div>
      <Typography
        variant="h4"
        gutterBottom
        fontWeight="bold"
        textAlign="center"
        id="lore"
      >
        LORE
      </Typography>
      <Waypoint onEnter={handleEnterViewport}>
        <div style={{ width: "100%" }}>
          <ReactPlayer
            controls
            url="/lore/IMG_1054.MP4"
            style={{ margin: "0 auto", width: "100%" }}
            playing={shouldPlay}
            volume={0.2}
            width="100%"
            height="auto"
          />
        </div>
      </Waypoint>
    </div>
  );
};
export default Lore;
