import React from "react";
import { Stack, Typography } from "@mui/material";
import { SocialIcon } from "react-social-icons";

export const JoinUs = () => (
  <div>
    <Typography variant="h4" gutterBottom id="socials">
      Follow us!
    </Typography>
    <Stack direction="row" spacing={2} justifyContent="center" mb="4em">
      <SocialIcon url="https://twitter.com/PixelPopsWorld" fgColor="white" />
      <SocialIcon url="https://discord.gg/YJcsqwnk5C" fgColor="white" />
    </Stack>
  </div>
);
export default JoinUs;
