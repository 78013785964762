import { InjectedConnector } from "@web3-react/injected-connector";
import { AbstractConnector } from "@web3-react/abstract-connector";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

export const injected = new InjectedConnector({ supportedChainIds: [1] });

export const walletconnect = new WalletConnectConnector({
  infuraId: "27e484dcd9e3efcfd25a83a78777cdf1",
});

export enum ConnectorNames {
  Injected = "Injected",
  WalletConnect = "WalletConnect",
}
export enum ExtraConnector {
  None = "None",
}

export const connectorsByName = {
  [ConnectorNames.Injected]: injected,
  [ConnectorNames.WalletConnect]: walletconnect,
};
