import { useWeb3React } from "@web3-react/core";
import { Web3Provider } from "@ethersproject/providers";
import { useCallback, useState } from "react";
import { ChainId, useConfig } from "@usedapp/core";
import { ConnectorNames, connectorsByName, ExtraConnector } from "./connectors";
import { WalletConnectConnector } from "@web3-react/walletconnect-connector";

type Connectors = ConnectorNames | ExtraConnector;

type ActivateWallet = (
  connector: ConnectorNames,
  onError?: (error: Error) => void,
  throwErrors?: boolean
) => void;

export type Web3Ethers = ReturnType<typeof useWeb3React> & {
  library?: Web3Provider;
  chainId?: ChainId;
  activateWallet: ActivateWallet;
};

export function useEthers(): Web3Ethers {
  const result = useWeb3React<Web3Provider>();
  const { supportedChains } = useConfig();

  const activateWallet: ActivateWallet = async (
    connector,
    onError,
    throwErrors
  ) => {
    await result.deactivate();
    if (onError instanceof Function) {
      await result.activate(connectorsByName[connector], onError, throwErrors);
    } else {
      console.log("activate");
      if (
        connectorsByName[connector] instanceof WalletConnectConnector &&
        (connectorsByName[connector] as any).walletConnectProvider?.wc?.uri
      ) {
        (connectorsByName[connector] as any).walletConnectProvider = undefined;
      }
      try {
        await result.activate(
          connectorsByName[connector],
          undefined,
          throwErrors
        );
      } catch (e) {
        console.log("e", e);
      }
      console.log("pass", result);
      if (connector == ConnectorNames.WalletConnect && result.connector) {
        console.log("here?");
        const a = (await result.connector.getProvider()).enable();
      }
    }
  };
  return { ...result, activateWallet };
}
