import React, { PureComponent } from "react";
import styled from "styled-components";
import logoSrc from "../assets/png/logo.png";
import { ReactComponent as burger } from "../assets/svg/burger.svg";
import { Box } from "@mui/system";
import Toolbar from "@mui/material/Toolbar";
import { IconButton, Menu as MenuMui, MenuItem, Stack } from "@mui/material";
import { SocialIcon } from "react-social-icons";

const logoImg = (props) => <img src={logoSrc} {...props} />;

const Logo = styled(logoImg)`
  width: 100%;
  max-width: 180px;
  @media only screen and (min-width: 1200px) {
    padding: 20px 0px;
    max-width: 299px;
  }
`;

const StyledIconButton = styled(IconButton)`
  width: 60px;
  height: 60px;
  @media only screen and (min-width: 1200px) {
    width: 80px;
    height: 80px;
  }
`;
const MenuButton = styled(burger)`
  max-width: 80px;
  width: 100%;
`;

const SBox = styled(Box)``;
const StyledToolbar = styled(Toolbar)`
  @media all and (min-width: 1921px) {
    padding: 0px 0px !important;
    width: 1920px;
    ${SBox} {
      padding: 20px 50px;
    }
  }
`;

const MenuItemSc = styled(MenuItem)``;
const PixelMenu = styled(MenuMui)`
  &&&&& {
    ${MenuItemSc} {
      color: black;
    }
  }
`;
export const Menu = (props) => {
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const open = !!anchorMenu;
  const handleMenuClick = (event) => {
    setAnchorMenu(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorMenu(null);
  };
  const scrollTo = (id) => {
    document
      .getElementById(id)
      .scrollIntoView({ behavior: "smooth", block: "start" });
    handleClose();
  };

  return (
    <StyledToolbar
      sx={{ flexGrow: 1, position: "absolute", width: "100%", zIndex: 10 }}
      {...props}
    >
      <SBox
        sx={{ width: "100%" }}
        justifyContent="space-between"
        justifyItems="center"
        alignItems="center"
        display="flex"
      >
        <Logo />

        <Box display="flex" alignItems="center" justifyContent="center">
          <Stack direction="row" spacing={2} justifyContent="center" mr={2}>
            <SocialIcon
              url="https://twitter.com/PixelPopsWorld"
              fgColor="white"
              style={{ width: "40px", height: "40px" }}
            />
            <SocialIcon
              url="https://discord.gg/YJcsqwnk5C"
              fgColor="white"
              style={{ width: "40px", height: "40px" }}
            />
          </Stack>
          <StyledIconButton
            color="inherit"
            aria-label="menu"
            onClick={handleMenuClick}
          >
            <MenuButton />
          </StyledIconButton>

          <PixelMenu
            id="basic-menu"
            anchorEl={anchorMenu}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "basic-button",
            }}
            disableAutoFocusItem
            autoFocus={false}
            disableScrollLock
            disableRestoreFocus
          >
            <MenuItemSc onClick={() => scrollTo("lore")}>LORE</MenuItemSc>
            <MenuItemSc onClick={() => scrollTo("mechanics")}>
              MECHANICS
            </MenuItemSc>
            <MenuItemSc onClick={() => scrollTo("faq")}>FAQ</MenuItemSc>
            <MenuItemSc onClick={() => scrollTo("socials")}>SOCIALS</MenuItemSc>
          </PixelMenu>
        </Box>
      </SBox>
    </StyledToolbar>
  );
};

export default Menu;
