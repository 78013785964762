import React from "react";
import { Container, Stack } from "@mui/material";
import { SocialIcon } from "react-social-icons";
import { Copyright } from "../components/Copyright";

export const Footer = () => (
  <Container maxWidth="sm" sx={{ mt: "2em" }}>
    <Copyright />
  </Container>
);
