import React, { PureComponent } from "react";
import styled, { css, StyledComponent } from "styled-components";
import Img from "../StyledImg";
import { ReactComponent as spark } from "../../assets/svg/spark.svg";
import coinOneSrc from "../../assets/png/coinInputOne.png";
import coinTwoSrc from "../../assets/png/coinInputTwo.png";
import arrowDown from "../../assets/png/arrow.png";
import { Box } from "@mui/system";

const ArrowContainer = styled.div`
  height: 40%;
  width: 100%;
  position: relative;
`;

const Arrow = styled(Img)`
  animation: MoveUpDown 1s linear infinite;

  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 0;
    }
    50% {
      bottom: 12px;
    }
  }

  position: absolute;
  left: 0;
  bottom: 2px;
  width: 100%;
`;

const SparkContainer = styled.div`
  position: absolute;
  left: 30%;
  top: 20px;
  z-index: 1;
  transform: rotate(20deg) translate(-50%, -50%);
`;

const ButtonContainer = styled.div`
  position: relative;
  width: 100%;
  & > * {
    width: 100%;
  }
`;
const Spark: StyledComponent<
  React.FunctionComponent<
    React.SVGProps<SVGSVGElement> & {
      title?: string;
    }
  >,
  any,
  {
    $clicked: boolean;
  },
  never
> = styled(spark)`
  ${({ $clicked }: { $clicked: boolean }) =>
    $clicked &&
    css`
      animation: spark 0.25s ease forwards;
    `}

  opacity: 0;
  visibility: hidden;
  transform: scale(0.5);
  margin: 0 auto;
  display: block;

  @keyframes spark {
    20% {
      opacity: 1;
      visibility: visible;
    }

    70% {
      opacity: 1;
    }

    100% {
      opacity: 0;
      transform: scale(2);
    }
  }
`;
const CoinTwo = styled(Img)``;

const InputContainer = styled(Box)`
  max-width: 38px;
  width: 100%;
  & > ${Img} {
    width: 100%;
  }
  height: 90px;

  @media only screen and (min-width: 600px) {
    max-width: 45px;
    height: 110px;
  }

  @media only screen and (min-width: 1367px) {
    max-width: 55px;
    height: 140px;
  }

  @media only screen and (min-width: 1537px) {
    max-width: 68px;
    height: 160px;
  }
`;
interface CoinInputProps {
  entered: boolean;
  onClick: () => void;
}
const coinInput: React.FunctionComponent<CoinInputProps> = ({
  entered,
  ...props
}) => {
  return (
    <InputContainer
      {...props}
      display="flex"
      flexDirection="column"
      justifyContent="space-between"
      alignItems="center"
    >
      <ArrowContainer>
        <Arrow src={arrowDown} />
      </ArrowContainer>
      <ButtonContainer>
        {entered ? <CoinTwo src={coinTwoSrc} /> : <Img src={coinOneSrc} />}
        <SparkContainer>
          <Spark $clicked={entered} />
        </SparkContainer>
      </ButtonContainer>
    </InputContainer>
  );
};
export const CoinInput = styled(coinInput)``;
export default CoinInput;
