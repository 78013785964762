import React, { useState } from "react";
import styled from "styled-components";
import ballMachineSrc from "../../assets/png/ballMachine.png";
import { CoinInput } from "./CoinInput";
import { MintOutput } from "./MintOutput";
import { MintInput } from "./MintInput";

const ballMachine = (props) => (
  <div {...props}>
    {props.children}
    <img className="ballMachine" src={ballMachineSrc} />
  </div>
);

const BallMachine = styled(ballMachine)`
  position: relative;
  max-width: 380px;
  & > img {
    margin: 0 auto;
    width: 100%;
  }
  & > ${MintInput} {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%);

    @media only screen and (min-width: 500px) {
    }
  }

  & > ${CoinInput} {
    position: absolute;
    top: 67%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export function LuckMachine(props) {
  const [pushed, setPush] = useState(false);

  return (
    <BallMachine {...props}>
      <MintInput />
      <CoinInput
        onClick={() => {
          setPush(!pushed);
        }}
        entered={pushed}
      />
      <MintOutput $pushed={pushed} />
    </BallMachine>
  );
}

export default LuckMachine;
