import React from "react";
import styled from "styled-components";
import { Typography, Box, Paper } from "@mui/material";
import logoLargeSrc from "../../assets/png/LogoLarge.png";

const LogoImg = (props) => <img src={logoLargeSrc} {...props} />;
const LogoLarge = styled(LogoImg)`
  width: 100%;
  max-width: 580px;
`;

const gangBox = (props) => (
  <div>
    <Paper {...props}>{props.svg ? props.svg : ""}</Paper>
    <Typography textAlign="center">{props.gang}</Typography>
  </div>
);

const GangBox = styled(gangBox)`
  width: 100px;
  height: 100px;
  background: ${(props) => props.backgroundColor};
`;

export const Mechanics = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    justifyItems="center"
    sx={{ width: "100%" }}
  >
    <Typography
      variant="h4"
      gutterBottom
      fontWeight="bold"
      textAlign="center"
      id="mechanics"
    >
      RPG MECHANICS
    </Typography>
    <Typography
      mt={4}
      variant="h5"
      gutterBottom
      fontWeight="bold"
      textAlign="center"
    >
      GANGS
    </Typography>
    <Typography>
      Each Pixel POP, apart from random attributes that characterize its rarity,
      has a Respek counter and belongs to one of the four Gangs. The four Gangs
      are identified by the next background colors:
    </Typography>
    <Box
      display="flex"
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      mt={4}
      sx={{ width: "100%" }}
      flexWrap="wrap"
    >
      <GangBox backgroundColor="#ffe494" gang="gm" svg="" />
      <GangBox backgroundColor="#d0f4ac" gang="wagmi" svg="" />
      <GangBox backgroundColor="#f88c8c" gang="rekt" svg="" />
      <GangBox backgroundColor="#b0acf4" gang="few" svg="" />
    </Box>
    <Typography mt={2}>
      The gang your Pixel Pop belongs to is so important! The most popular gangs
      will receive rewards, but they also are the most wanted. The way to
      determine which Pixel Pops or Gang is better than the others is through
      Respek.
    </Typography>
    <Typography
      mt={4}
      variant="h5"
      gutterBottom
      fontWeight="bold"
      textAlign="center"
    >
      RESPEKT AS A MEANING OF LIFE
    </Typography>
    <Typography>
      In addition to the gang to which they belong, each Pixel POP has Respek.
    </Typography>
    <Typography>
      Each Pixel Pops is born with a random amount of Respek between 1 and 100
      but there is not a max quantity possible. The Respek of each gang is the
      sum of each member’s Respek. The utility of Respek is:
    </Typography>
    <ul>
      <li>
        Giving Away weekly emission of $PIXELs: The better positioned a gang is
        within Respek's weekly ranking, the more PIXELs they will have to
        distribute among its alive members.
      </li>
      <li>
        To access and complete MISSIONS: Your Pixel Pop must have a minimum
        amount of Respek (varying on each mission) to do them. Higher Respek
        means higher probabilities of success.
      </li>
    </ul>
    <Typography
      mt={4}
      variant="h5"
      gutterBottom
      fontWeight="bold"
      textAlign="center"
    >
      HOW I GET RESPEKTED?
    </Typography>
    <Typography gutterBottom>
      The main way is completing Missions successfully. Missions will be short
      graphic novels with different ways to choose. You’ll need a minimum of
      Respek to access a mission and can either fall or succeed depending on
      your decisions. In any decision, you will know the possibilities of
      success and failure and the possible rewards or sanctions derivatives from
      it.
    </Typography>
    <Typography>
      The second way, ‘Murdering’ other Pixel Pops. Kill a Pixel Pops is a smart
      contract event where you need to:
      <ul>
        <li> Have 2 Pixel Pops of different Gangs in your wallet. </li>{" "}
        <li>
          Choose killer and victim. Killer absorbs all the Respek from the
          victim. If the victim belongs to the first or second most Respekted
          Gang, the absorbed Respek is multiplied by 1.5 or 1.2 respectively.
          The Pixel Pop murdered will be transferred to the ‘Graveyard‘ forever.
        </li>
      </ul>
    </Typography>
  </Box>
);
export default Mechanics;
