import React from "react";
import styled from "styled-components";
import { Typography, Box, Paper } from "@mui/material";
import logoLargeSrc from "../../assets/png/LogoLarge.png";

const LogoImg = (props) => <img src={logoLargeSrc} {...props} />;
const LogoLarge = styled(LogoImg)`
  width: 100%;
  max-width: 580px;
`;

const gangBox = (props) => (
  <div>
    <Paper {...props}>{props.svg ? props.svg : ""}</Paper>
    <Typography textAlign="center">{props.gang}</Typography>
  </div>
);

const List = styled.ul`
  list-style: none;
  margin: 10px 0px;
  padding: 0;
  & > li {
    margin-top: 20px;
  }
`;

const GangBox = styled(gangBox)`
  width: 100px;
  height: 100px;
  background: ${(props) => props.backgroundColor};
`;

export const Intro = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    justifyItems="center"
    alignItems="center"
    sx={{ width: "100%" }}
  >
    <LogoLarge />
    <Typography mt={4}>
      Welcome to the Pixel Pops World! A Role-Playing Game where every character
      is an NFT! Make your Gang grow, complete crazy missions or kill other
      Pixel Pops to earn <b>$PIXEL</b> and become the most respekted Pixel Pops
      of the universe.
    </Typography>
  </Box>
);
export default Intro;
