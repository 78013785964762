import React from "react";
import styled from "styled-components";
import terrain from "../assets/png/terrain.png";
import LuckMachine from "../components/LuckMachine";

const StyledLuckMachine = styled(LuckMachine)`
  position: absolute;
  width: 190px;

  top: 256px;
  left: 50%;
  transform: translate(-50%, -50%);

  @media all and (min-width: 600px) {
    width: 280px;
    top: 286px;
  }

  @media all and (min-width: 1367px) {
    width: 334px;
    top: 316px;
  }

  @media all and (min-width: 1537px) {
    width: 370px;
    top: 418px;
  }
  @media all and (min-width: 1921px) {
    top: 418px;
  }
`;

const HomeContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  background-color: #6bccf8;
  background-image: url(${terrain});
  background-size: auto 75%;
  background-repeat: no-repeat;
  background-position: bottom;

  @media only screen and (min-width: 600px) {
    background-size: auto 100%;
  }

  @media only screen and (min-width: 1920px) {
    background-size: 100% auto;
    background-position: center;
  }

  @media only screen and (min-width: 1900px) {
    margin-top: 0;
  }
`;

export const Home = (props) => {
  return (
    <HomeContainer {...props}>
      <StyledLuckMachine />
    </HomeContainer>
  );
};

export default Home;
