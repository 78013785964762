import * as React from 'react';
import ReactDOM from 'react-dom';
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import App from './App';
import theme from './theme';
import { ChainId, DAppProvider, Config } from '@usedapp/core'
import { EtherWeb3Provider } from './connectors/Web3Provider';

const config: Config = {
  readOnlyChainId: ChainId.Mainnet,
  readOnlyUrls: {
    [ChainId.Mainnet]: 'https://mainnet.infura.io/v3/62687d1a985d4508b2b7a24827551934',
  },
}

ReactDOM.render(
  <ThemeProvider theme={theme}>
    <CssBaseline />
    <DAppProvider config={config}>
      <EtherWeb3Provider>
        <App />
      </EtherWeb3Provider>
    </DAppProvider>
  </ThemeProvider>,
  document.querySelector('#root'),
);
