import { Typography } from "@mui/material";

export function Copyright() {
  return (
    <Typography
      variant="body2"
      color="text.primary"
      align="center"
      sx={{ mb: 4 }}
    >
      {"Copyright © "}
      {new Date().getFullYear()}
      {" Pixel Pops. All rights reserved."}
    </Typography>
  );
}
