import React, { PureComponent } from "react";
import styled from "styled-components";
import FuckingPcSrc from "../assets/png/fuckingPc.png";

const TV = styled.div`
  position: relative;
  width: 100%;
  height: 550px;

  @media all and (min-width: 600px) {
    height: 768px;
  }

  @media all and (min-width: 1367px) {
    max-width: 1920px;
    height: 880px;
  }

  @media all and (min-width: 1537px) {
    height: 1080px;
  }

  @media all and (max-width: 1920px) {
    visibility: hidden;

    & > * {
      visibility: visible;
    }
  }

  @media all and (min-width: 1921px) {
    max-width: 2130px;
    width: 2120px;
    height: 1290px;
    padding: 100px 100px 110px 100px;
    margin-top: 40px;

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      display: block;
      width: 2100px;
      height: 1290px;
      background-image: url(${FuckingPcSrc});
      background-repeat: no-repeat;
      background-size: 100%;
      background-position: center;
    }
  }
`;
export const TVFrame = (props) => <TV {...props}>{props.children}</TV>;

export default TVFrame;
