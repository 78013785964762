import React from "react";
import { Box, Typography } from "@mui/material";

const faqList = [
  [
    "Where the Pixel Pops NFTs metadata and images are stored?",
    "All the items, metadata, and Pixel Pops are immutably stored on-chain inside the NFT smart contract. This enables that your Pixel Pop will exist forever at the Avalanche C-Chain blockchain, without the need for 3rd parties, IPFS, or any other non-fault tolerant storage.",
  ],
  [
    "Mint date?",
    "We will announce the mint date as soon as Chainlink VRF is available on Avalanche. We need this tool to achieve our goal to do a totally on chain and randomly verifiable RPG metaverse.",
  ],
  [
    "Mint price?",
    "The price will be determined through a reverse dutch auction at the main sale.",
  ],
  [
    "What about whale or bot prevention?",
    "Depending of the expected demand we will implement different mechanisms like a raffle system prior the main sale or quantity limits or being registered at Proof of Humanity.",
  ],
  [
    "Do you have a whitelist or presale?",
    "No, we want the sale to be open to everyone without limits or preferences.",
  ],
  ["What blockchain will you be using?", "Avalanche C-Chain."],
  ["What are your secondary royalty fees?", "3%"],
  [
    "What is $PIXEL?",
    "$PIXEL is the main currency of Pixel Pops metaverse. Will be distributed weekly to the Pixel Pops players and gangs, depending of their ranking position. $PIXEL is the only way to buy weapons, vehicles, pets, random consumables and other wearables that can increase the RESPEK levels of your Pixel Pop. Also, you will be available to mint next-gen special editions of Pixel Pops with $PIXEL.",
  ],
  ["When will $PIXEL tokenomics be published?", "Early Q1 2022."],
];

export const Faq = () => (
  <Box
    display="flex"
    flexDirection="column"
    justifyContent="center"
    justifyItems="center"
    sx={{ width: "100%" }}
  >
    <Typography
      variant="h4"
      gutterBottom
      fontWeight="bold"
      textAlign="center"
      id="faq"
    >
      FAQ
    </Typography>
    {faqList.map(([q, a]) => (
      <>
        <Typography gutterBottom fontWeight="bold" fontSize="22px">
          {q}
        </Typography>
        <Typography mb={4}>{a}</Typography>
      </>
    ))}
  </Box>
);
export default Faq;
