import React from "react";
import styled from "styled-components";
import mintSrc from "../../assets/png/mint.png";
import TextField from "@mui/material/TextField";

const TextInput = styled(TextField)``;

const mintBox = (props) => <div {...props}>{props.children}</div>;

const MintBox = styled(mintBox)`
  background-image: url(${mintSrc});
  background-repeat: no-repeat;
  background-size: contain;
  display: flex;
  width: 160px;
  height: 70px;
  align-items: center;
  justify-content: flex-end;
  & > ${TextInput} {
    width: 40px;
    margin-right: 10px;
  }
  @media only screen and (min-width: 1200px) {
    width: 240px;
    height: 100px;
    & > ${TextInput} {
      width: 40px;
      margin-right: 30px;
    }
  }
`;

const mintInput = (props) => (
  <MintBox {...props}>
    <TextInput value="10" variant="standard" />
  </MintBox>
);
export const MintInput = styled(mintInput)``;
export default MintInput;
