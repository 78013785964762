import React from "react";
import styled from "styled-components";
import openBallSrc from "../../assets/png/openBall.png";
import incomingBallSrc from "../../assets/gif/IncomingBall.gif";
import movingBallSrc from "../../assets/gif/MovingBall.gif";
import StyledImg from "../StyledImg";

const OpenBall = StyledImg;
const IncomingBall = StyledImg;
const MovingBall = StyledImg;

interface MintOutputProps {
  $pushed: boolean;
}

export const MintOutput: React.FunctionComponent<MintOutputProps> = ({
  $pushed,
  ...props
}) => {
  return (
    <div {...props}>
      <IncomingBall />
    </div>
  );
};

export default MintOutput;
